import React, { useState } from "react";
import { HiArrowPath } from "react-icons/hi2";
import AddRemoveHeaderNavigation from "../components/common/AddRemoveHeaderNavigation";
import SwapInputBox from "../components/SwapInputBox";
import TokenModal from "../components/TokenModal";

const AddLiquidity = () => {
  const [toggleModal, setToggleModal] = useState(false);
  return (
    <div className="min-h-full z-10 w-full flex justify-center text-white">
      <div className="my-8 z-10 md:w-[450px] w-[90%] rounded-xl md:p-6 p-4 backdrop-blur-sm bg-black-shade/30">
        <AddRemoveHeaderNavigation />
        <SwapInputBox title="From" setShow={setToggleModal} />
        <SwapInputBox title="To (Estimated)" setShow={setToggleModal} />
        <div className="mt-4">
          <button className="w-full p-2 text-xl bg-blue rounded-lg hover:opacity-75">Add Liquidity</button>
        </div>
      </div>
      <TokenModal show={toggleModal} setShow={setToggleModal} />
    </div>
  );
};

export default AddLiquidity;
