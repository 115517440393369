import React from "react";
import SwapPoolHeaderNavigation from "../components/common/SwapPoolHeaderNavigation";
import { Link } from "react-router-dom";
import LiquidityAccordion from "../components/common/LiquidityAccordion";
import { useNavigate } from "react-router-dom";

const Pool = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-full w-full flex justify-center text-white">
      <div className="my-8 md:w-[450px] w-[90%] rounded-xl md:p-6 p-4 backdrop-blur-sm bg-black-shade/30">
        <SwapPoolHeaderNavigation />
        <div className="my-6">
          <button
            className="w-full p-2 text-lg bg-blue rounded-xl hover:opacity-75"
            onClick={() => navigate("/add-liquidity")}
          >
            Add Liquidity
          </button>
        </div>
        <div className="my-4 text-white/60">Yout liquidity</div>
        <div className="rounded-lg border p-2 mb-5 max-h-[500px] overflow-auto">
          <LiquidityAccordion />
          <LiquidityAccordion />
          <LiquidityAccordion />
          <LiquidityAccordion />
        </div>
        <hr className="opacity-25" />
        <div className="text-white/65 mt-5">
          <p className="">
            Don't see a pool you joined?{" "}
            <Link to="/find-pool" className="text-yellow underline">
              import it
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pool;
