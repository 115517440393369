import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import ConnectWalletButton from "../ConnectWalletButton";
import ChainDropdown from "../ChainDropdown";

const Header = () => {
  return (
    <div className="w-full h-[70px] flex sm:justify-between justify-center">
      <div className=""></div>
      <div className="flex h-full justify-end items-center">
        <button className="mx-2 text-white text-3xl">
          <HiOutlineUserCircle />
        </button>
        <div className="mx-2 text-white relative">
          <div className="rounded-xl bg-black flex">
            <ChainDropdown />
            <ConnectWalletButton />
          </div>
        </div>
        <div className="mx-2 text-white">
          <button className="rounded-xl bg-black p-2">
            <img src="assets/images/threedots.png" className="w-[22px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
