import { createContext, useContext, useEffect, useState } from "react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { metadata, walletChains } from "./constants/walletChains";
import useGetChains from "./hooks/useGetChains";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import COINS from "./constants/coins";
import { getRouter, getFactory } from "./functions/ethersFunctions";
// import COINS from "./constants/coins";

export const globalContext = createContext(null);

// 1. Get projectId
const projectId = process.env.REACT_APP_PROJECT_ID;

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: walletChains,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const GlobalContext = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    slippage: "",
    slippagePlaceholder: "0.5",
    deadline: "",
    deadlinePlaceholder: "20",
    expertMode: false,
    testnet: false,
  });
  const chains = useGetChains(settings);
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const chain = queryParams.get("chain");
  const inputCurrency = queryParams.get("inputCurrency");
  const outputCurrency = queryParams.get("outputCurrency");
  const [selectedChain, setSelectedChain] = useState();
  const [networkInfo, setNetworkInfo] = useState(null);

  const getAndSetNetworkInfo = async () => {
    try {
      if (isConnected && selectedChain && walletProvider) {
        const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await ethersProvider.getSigner();
        if (ethersProvider && signer) {
          let tokens = COINS.get(selectedChain?.chainId);
          let router = await getRouter(selectedChain?.router, signer);
          let wethAddress = await router.WETH();
          if (wethAddress) {
            tokens[0].address = wethAddress;
          }
          let factoryAddress = await router.factory();
          let factory = await getFactory(factoryAddress, signer);
          setNetworkInfo((prev) => ({
            ...prev,
            factory,
            factoryAddress,
            wethAddress,
            router,
            tokens: [...tokens],
            provider: ethersProvider,
            signer: signer,
          }));
        }
      } else {
        if (selectedChain) {
          let tokens = COINS.get(selectedChain?.chainId);
          setNetworkInfo((prev) => ({
            ...prev,
            factory: null,
            factoryAddress: null,
            wethAddress: null,
            router: null,
            tokens: [...tokens],
            provider: null,
            signer: null,
          }));
        } else {
          let tokens = COINS.get(1);
          setNetworkInfo((prev) => ({
            ...prev,
            factory: null,
            factoryAddress: null,
            wethAddress: null,
            router: null,
            tokens: [...tokens],
            provider: null,
            signer: null,
          }));
        }
      }
    } catch {
      if (selectedChain) {
        let tokens = COINS.get(selectedChain?.chainId);
        setNetworkInfo((prev) => ({
          ...prev,
          factory: null,
          factoryAddress: null,
          wethAddress: null,
          router: null,
          tokens: [...tokens],
          provider: null,
          signer: null,
        }));
      } else {
        let tokens = COINS.get(1);
        setNetworkInfo((prev) => ({
          ...prev,
          factory: null,
          factoryAddress: null,
          wethAddress: null,
          router: null,
          tokens: [...tokens],
          provider: null,
          signer: null,
        }));
      }
    }
  };

  useEffect(() => {
    getAndSetNetworkInfo();
  }, [selectedChain, chainId, address, isConnected, walletProvider]);

  const getAndSetSelectedChain = () => {
    if (chainId && chains && chains && isConnected) {
      let find = null;
      find = chains?.mainnets?.find((item) => item.chainId === chainId);
      if (!find) {
        find = chains?.testnets?.find((item) => item.chainId === chainId);
      }
      if (find) {
        setSelectedChain(find);
      } else {
        setSelectedChain(null);
      }
    } else if (!selectedChain && chains) {
      setSelectedChain(chains?.mainnets[0]);
    }
  };

  useEffect(() => {
    getAndSetSelectedChain();
  }, [chains, chainId]);

  const setUrlSearchParams = (input, output, swap = false) => {
    let root = pathname;
    let chainStr = "";
    let inputStr = "";
    let outputStr = "";
    if (selectedChain) {
      chainStr = `?chain=${selectedChain?.urlname}`;
    }
    if (input) {
      inputStr = chainStr ? `&inputCurrency=${input?.address}` : `?inputCurrency=${input?.address}`;
    } else {
      inputStr = chainStr
        ? inputCurrency
          ? `&inputCurrency=${inputCurrency}`
          : ""
        : inputCurrency
        ? `?inputCurrency=${inputCurrency}`
        : "";
    }
    if (output) {
      outputStr =
        chainStr || inputStr ? `&outputCurrency=${output?.address}` : `?outputCurrency=${output?.address}`;
    } else {
      outputStr =
        chainStr || inputStr
          ? outputCurrency
            ? `&outputCurrency=${outputCurrency}`
            : ""
          : outputCurrency
          ? `?outputCurrency=${outputCurrency}`
          : "";
    }
    if (!input && inputCurrency?.toLowerCase() !== networkInfo?.tokens[0]?.address && networkInfo?.tokens) {
      inputStr = chainStr
        ? `&inputCurrency=${networkInfo?.tokens[0]?.address}`
        : `?inputCurrency=${networkInfo?.tokens[0]?.address}`;
    }
    if (inputCurrency && output && inputCurrency?.toLowerCase() === output?.address?.toLowerCase()) {
      if (outputCurrency) {
        inputStr = chainStr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
      } else {
        inputStr = "";
      }
      outputStr =
        chainStr || inputStr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
    }
    if (outputCurrency && input && outputCurrency?.toLowerCase() === input?.address?.toLowerCase()) {
      if (inputCurrency) {
        outputStr =
          chainStr || inputStr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
      } else {
        outputStr = "";
      }
      inputStr = chainStr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
    }

    if (swap) {
      if (inputCurrency) {
        outputStr =
          chainStr || inputStr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
      } else {
        outputStr = "";
      }
      if (outputCurrency) {
        inputStr = chainStr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
      } else {
        inputStr = "";
      }
    }

    navigate({ pathname: root, search: `${chainStr}${inputStr}${outputStr}` });
  };

  useEffect(() => {
    setUrlSearchParams();
  }, [selectedChain, networkInfo?.tokens]);

  return (
    <globalContext.Provider
      value={{ settings, setSettings, selectedChain, setSelectedChain, networkInfo, setUrlSearchParams }}
    >
      {children}
    </globalContext.Provider>
  );
};

export default GlobalContext;
