import React, { useContext, useEffect, useState } from "react";
import { HiArrowPath } from "react-icons/hi2";
import SwapPoolHeaderNavigation from "../components/common/SwapPoolHeaderNavigation";
import SwapInputBox from "../components/SwapInputBox";
import TokenModal from "../components/TokenModal";
import { globalContext } from "../GlobalContext";
import { useLocation } from "react-router-dom";
import { getBalanceAndTokenInfo } from "../functions/ethersFunctions";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const Swap = () => {
  const { setUrlSearchParams, networkInfo } = useContext(globalContext);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const chain = queryParams.get("chain");
  const inputCurrency = queryParams.get("inputCurrency");
  const outputCurrency = queryParams.get("outputCurrency");

  const [selecteToken1, setSelecteToken1] = useState(null);
  const [selecteToken2, setSelecteToken2] = useState(null);

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [toggleModal1, setToggleModal1] = useState(false);
  const [toggleModal2, setToggleModal2] = useState(false);

  const setParam1 = (item) => {
    setUrlSearchParams(item, null);
    setToggleModal1(false);
  };

  const setParam2 = (item) => {
    setUrlSearchParams(null, item);
    setToggleModal2(false);
  };

  const getAndSetTokenInfo1 = async (inputToken) => {
    try {
      if (isConnected) {
        const info = await getBalanceAndTokenInfo(
          address,
          inputToken?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.wethAddress,
          networkInfo?.tokens,
          networkInfo?.router
        );
        if (info) {
          setSelecteToken1((prev) => ({
            ...prev,
            address: inputToken?.address,
            symbol: info?.symbol,
            allowance: info?.allowance ? Number(info?.allowance) : 0,
            balance: info?.balance ? Number(info?.balance) : 0,
          }));
        } else {
          setSelecteToken1(inputToken);
        }
      } else {
        setSelecteToken1(inputToken);
      }
    } catch {
      setSelecteToken1(inputToken);
    }
  };
  const getAndSetTokenInfo2 = async (outputToken) => {
    try {
      if (isConnected) {
        const info = await getBalanceAndTokenInfo(
          address,
          outputToken?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.wethAddress,
          networkInfo?.tokens,
          networkInfo?.router
        );
        if (info) {
          setSelecteToken2((prev) => ({
            ...prev,
            address: outputToken?.address,
            symbol: info?.symbol,
            allowance: info?.allowance ? Number(info?.allowance) : 0,
            balance: info?.balance ? Number(info?.balance) : 0,
          }));
        } else {
          setSelecteToken2(outputToken);
        }
      } else {
        setSelecteToken2(outputToken);
      }
    } catch {
      setSelecteToken2(outputToken);
    }
  };
  const setTokens = () => {
    if (inputCurrency) {
      const inputToken = networkInfo?.tokens?.find(
        (item) => item?.address?.toLowerCase() === inputCurrency?.toLowerCase()
      );
      if (inputToken) {
        getAndSetTokenInfo1(inputToken);
      } else {
        setSelecteToken1(null);
      }
    } else {
      setSelecteToken1(null);
    }
    if (outputCurrency) {
      const outputToken = networkInfo?.tokens?.find(
        (item) => item?.address?.toLowerCase() === outputCurrency?.toLowerCase()
      );

      if (outputToken) {
        getAndSetTokenInfo2(outputToken);
      } else {
        setSelecteToken2(null);
      }
    } else {
      setSelecteToken2(null);
    }
  };

  useEffect(() => {
    setTokens();
  }, [inputCurrency, outputCurrency, networkInfo?.tokens, address, chainId, isConnected]);

  const handleInput1 = async (value) => {
    try {
      setInputValue1(value);
      let amount = value ? value : inputValue1;
      if (amount) {
      } else {
        setInputValue2("");
      }
    } catch {}
  };

  useEffect(() => {
    handleInput1();
  }, [selecteToken1, address, chainId, isConnected]);

  const handleInput2 = async (value) => {
    try {
      setInputValue2(value);
      let amount = value ? value : inputValue1;
      if (amount) {
      } else {
        setInputValue1("");
      }
    } catch {}
  };

  return (
    <div className="min-h-full z-10 w-full flex justify-center text-white">
      <div className="my-8 z-10 md:w-[450px] w-[90%] rounded-xl md:p-6 p-4 backdrop-blur-sm bg-black-shade/30">
        <SwapPoolHeaderNavigation />
        <SwapInputBox
          title="From"
          setShow={setToggleModal1}
          selecteToken={selecteToken1}
          inputValue={inputValue1}
          handleInput={handleInput1}
        />
        <div className="w-full flex justify-center">
          <button onClick={() => setUrlSearchParams(null, null, true)}>
            <img src="assets/images/svgs/coins-swap.svg" className="w-[36px]" />
          </button>
        </div>
        <SwapInputBox
          title="To (Estimated)"
          setShow={setToggleModal2}
          selecteToken={selecteToken2}
          inputValue={inputValue2}
          handleInput={handleInput2}
        />
        <div className="flex justify-center">
          1 USDT = 1000 USDC <HiArrowPath size={25} className="mx-2" />
        </div>
        <div className="my-4">
          <button className="w-full p-2 text-xl bg-black-shade/50 rounded-lg hover:opacity-75">Swap</button>
        </div>
        <div className="my-4 text-center">Enter an amount to see more trading details</div>
        <hr className="opacity-25" />
        <div className="my-4">
          <div className="flex justify-between my-3">
            <p>Minimum Received</p>
            <p className=" text-blue">11.58 AWN</p>
          </div>
          <div className="flex justify-between my-3">
            <p>Price Impact</p>
            <p className=" text-green">99.49%</p>
          </div>
          <div className="flex justify-between my-3">
            <p>Liquidity Provider Fee</p>
            <p className=" text-yellow">0.248 BUSD</p>
          </div>
        </div>
      </div>
      <TokenModal
        show={toggleModal1}
        setShow={setToggleModal1}
        setParam={setParam1}
        // selecteToken={selecteToken1}
        selecteToken1={selecteToken1}
        selecteToken2={selecteToken2}
      />
      <TokenModal
        show={toggleModal2}
        setShow={setToggleModal2}
        setParam={setParam2}
        // selecteToken={selecteToken2}
        selecteToken1={selecteToken1}
        selecteToken2={selecteToken2}
      />
    </div>
  );
};

export default Swap;
