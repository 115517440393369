import React, { useRef, useEffect, useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { globalContext } from "../GlobalContext";

const TokenModal = ({ show, setShow, setParam, selecteToken, selecteToken1, selecteToken2 }) => {
  const { networkInfo } = useContext(globalContext);
  return (
    <div
      className={`fixed top-0 z-20 left-0 min-h-screen w-full flex justify-center items-center bg-black-shade/20 ${
        show ? "" : "hidden"
      }`}
    >
      <div className="w-[400px] min-h-[500px] max-h-[600px] mx-4 md:m-0 md:p-4 p-2 rounded-lg backdrop-blur-md bg-black-shade/30">
        <div className="flex justify-between">
          <div className="">Select a Token</div>
          <div className="text-white">
            <button className="" onClick={() => setShow(false)}>
              <RxCross2 size="28px" />
            </button>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center p-1 px-2 border rounded-lg opacity-60 hover:opacity-100 focus:opacity-100">
            <button className="mr-1">
              <IoSearchOutline />
            </button>
            <input
              type="text"
              placeholder="Search"
              className="w-full text-lg outline-none"
              style={{ backgroundColor: "transparent" }}
            />
          </div>
        </div>
        <div className="flex gap-2 mt-3">
          {networkInfo && networkInfo?.tokens?.length
            ? networkInfo?.tokens?.map((item) => {
                return item?.special ? (
                  <button
                    className="w-1/4 rounded-lg p-2 px-1 border text-sm flex justify-center items-center hover:bg-black-shade disabled:opacity-55"
                    key={item.address}
                    onClick={() => setParam(item)}
                  >
                    <img
                      src={item.icon ? item.icon : "assets/images/unknown.svg"}
                      style={{ height: "20px", width: "20px" }}
                      className="mr-1"
                    />
                    <p>{item.symbol}</p>
                  </button>
                ) : (
                  ""
                );
              })
            : ""}
        </div>
        <hr className="opacity-25 my-3" />
        <div className="max-h-[350px] overflow-auto">
          <ul>
            {networkInfo && networkInfo?.tokens?.length
              ? networkInfo?.tokens?.map((item) => {
                  return (
                    <li
                      className={`flex p-2 hover:bg-black-shade rounded-lg disabled:opacity-55 cursor-pointer ${
                        item.address?.toLowerCase() === selecteToken1?.address?.toLowerCase() ||
                        item.address?.toLowerCase() === selecteToken2?.address?.toLowerCase()
                          ? "opacity-60"
                          : ""
                      }`}
                      key={item.address}
                      onClick={() => setParam(item)}
                    >
                      <div className="w-full flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={item.icon ? item.icon : "assets/images/unknown.svg"}
                            style={{ height: "20px", width: "20px" }}
                            className="mx-1"
                          />
                          <p>
                            {item.name} ({item.symbol})
                          </p>
                        </div>
                        <p className="mx-1 flex justify-end">0</p>
                      </div>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TokenModal;
