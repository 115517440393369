import "./App.css";
import Header from "./components/common/Header";
import AddLiquidity from "./pages/AddLiquidity";
import ImportPool from "./pages/ImportPool";
import Pool from "./pages/Pool";
import RemoveLiquidity from "./pages/RemoveLiquidity";
import Swap from "./pages/Swap";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="ubuntu-light w-full min-h-screen flex justify-center bg-space-image">
      <div className="container">
        <Header />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Swap />} />
            <Route path="/pool" element={<Pool />} />
            <Route path="/add-liquidity" element={<AddLiquidity />} />
            <Route path="/remove-liquidity" element={<RemoveLiquidity />} />
            <Route path="/find-pool" element={<ImportPool />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
