import React from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import SettingDropdown from "../SettingDropdown";
import { FaArrowLeftLong } from "react-icons/fa6";

const AddRemoveHeaderNavigation = () => {
  const location = useLocation();
  const naivgate = useNavigate();
  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="rounded-full bg-black flex text-white text-md">
          <button className="p-2 px-3 rounded-full hover:opacity-75" onClick={() => naivgate("/pool")}>
            <FaArrowLeftLong />
          </button>
        </div>
      </div>
      <div className="flex">
        <div>
          <button className="p-2 mx-1 text-white bg-black-shade rounded-xl hover:opacity-75">
            <IoIosInformationCircleOutline size={25} />
          </button>
        </div>
        <SettingDropdown />
      </div>
    </div>
  );
};

export default AddRemoveHeaderNavigation;
