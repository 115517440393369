import ERC20 from "../abis/ERC20.json";
import V2FACTORY from "../abis/IUniswapV2Factory.json";
import V2PAIR from "../abis/IUniswapV2Pair.json";
import V2ROUTER from "../abis/UniswapV2Router02.json";
import { Contract } from "ethers";
import { formatWeiToEtherFixed, formatEtherFixed } from "./commonFunctions";

export async function getRouter(routerAddress, signer) {
  try {
    let router = new Contract(routerAddress, V2ROUTER.abi, signer);
    if (router) {
      return router;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in getRouter : ", err);
    return null;
  }
}

export async function getFactory(factoryAddress, signer) {
  try {
    let factory = new Contract(factoryAddress, V2FACTORY.abi, signer);
    if (factory) {
      return factory;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in getRouter : ", err);
    return null;
  }
}

export async function getErc20Token(tokenAddress, signer) {
  try {
    let token = new Contract(tokenAddress, ERC20.abi, signer);
    if (token) {
      return token;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error in getRouter : ", err);
    return null;
  }
}

export const getBalanceAndTokenInfo = async (user, tokenAddress, provider, signer, weth, coins, router) => {
  try {
    if (tokenAddress?.toLowerCase() === weth?.toLowerCase()) {
      const balanceRaw = await provider.getBalance(user);
      return {
        balance: formatWeiToEtherFixed(balanceRaw),
        symbol: coins[0].symbol,
      };
    } else {
      const token = new Contract(tokenAddress, ERC20.abi, signer);
      // const tokenDecimals = await getDecimals(token);
      const balanceRaw = await token.balanceOf(user);
      const allowance = await token.allowance(user, router.address);
      const symbol = await token.symbol();
      return {
        balance: formatWeiToEtherFixed(balanceRaw),
        symbol: symbol,
        allowance: parseFloat(allowance),
      };
    }
  } catch (err) {
    console.log("error in getBalanceAndTokenInfo : ", err);
    return false;
  }
};
