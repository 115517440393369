import React, { useEffect, useState } from "react";
import { MdLogout } from "react-icons/md";
import { useWeb3Modal, useWeb3ModalAccount, useDisconnect } from "@web3modal/ethers5/react";
import { formatAddress } from "../functions/commonFunctions";
import { CiWallet } from "react-icons/ci";

const ConnectWalletButton = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [togglePopup, setTogglePopup] = useState(false);
  const { disconnect } = useDisconnect();
  // const ref = useRef();

  useEffect(() => {
    const closeModal = (e) => {
      if (e.target.name !== "walletConnectPopup") {
        setTogglePopup(false);
      }
    };
    document.body.addEventListener("click", closeModal);
    return () => document.body.addEventListener("click", closeModal);
  }, []);

  return (
    <div>
      {!isConnected ? (
        <>
          <button
            className="bg-black-shade sm:block hidden p-2 px-6 rounded-xl"
            name="walletConnectPopup"
            onClick={open}
          >
            Connect to Wallet
          </button>
          <button
            className="bg-black-shade p-2 px-6 block sm:hidden rounded-xl text-white"
            name="walletConnectPopup"
            onClick={open}
          >
            <CiWallet size={25} />
          </button>
        </>
      ) : (
        <>
          <button
            className="bg-black-shade p-2 px-6 rounded-xl"
            name="walletConnectPopup"
            onClick={() => setTogglePopup(!togglePopup)}
          >
            {formatAddress(address)}
          </button>
          <div
            // ref={ref}
            id="walletConnectPopupContainer"
            className={`w-[250px] absolute z-30 md:right-0 right-[-10%] p-1 px-2 top-[100%] my-2 rounded-lg backdrop-blur-md bg-black-shade/30 ${
              togglePopup ? "" : "hidden"
            }`}
          >
            <ul>
              <li className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
                <div className="flex">
                  <p>Wallet</p>
                </div>
              </li>
              <li className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
                <div className="flex">
                  <p>Yout transactions</p>
                </div>
              </li>
              <hr className="opacity-25 my-1" />
              <li className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer" onClick={disconnect}>
                <div className="flex items-center justify-between">
                  <p>Disconnect</p>
                  <p className="">
                    <MdLogout size="22px" />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectWalletButton;
