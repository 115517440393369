import React, { useState } from "react";
import TokenModal from "../components/TokenModal";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SettingDropdown from "../components/SettingDropdown";
import RangeSlider from "../components/RangeSlider";

const RemoveLiquidity = () => {
  const navigate = useNavigate();
  const [toggleModal, setToggleModal] = useState(false);
  return (
    <div className="min-h-full z-10 w-full flex justify-center text-white">
      <div className="my-8 z-10 md:w-[450px] w-[90%] rounded-xl md:p-6 p-4 backdrop-blur-sm bg-black-shade/30">
        <div className="flex justify-between">
          <div className="flex">
            <div className="rounded-full bg-black flex text-white text-md">
              <button className="p-2 px-3 rounded-full hover:opacity-75" onClick={() => navigate("/pool")}>
                <FaArrowLeftLong />
              </button>
            </div>
            <div className="mx-2 flex items-center">
              <p className="text-lg">Remove liquidity</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <button className="p-2 mx-1 text-white bg-black-shade rounded-xl hover:opacity-75">
                <IoIosInformationCircleOutline size={25} />
              </button>
            </div>
            <SettingDropdown />
          </div>
        </div>
        <div className="w-full p-2 border rounded-xl my-2 mt-5">
          <div className="my-2">
            <RangeSlider />
          </div>
          <div className="flex gap-2 justify-center my-3">
            <button className="px-3 p-1 rounded-full border hover:opacity-75">25%</button>
            <button className="px-3 p-1 rounded-full border hover:opacity-75">50%</button>
            <button className="px-3 p-1 rounded-full border hover:opacity-75">75%</button>
            <button className="px-3 p-1 rounded-full border hover:opacity-75">100%</button>
          </div>
        </div>
        <div className="mt-4">
          <button className="w-full p-2 text-xl bg-red rounded-lg hover:opacity-75">Remove Liquidity</button>
        </div>
      </div>
    </div>
  );
};

export default RemoveLiquidity;
