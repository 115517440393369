import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import useGetChains from "../hooks/useGetChains";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { globalContext } from "../GlobalContext";

const ChainDropdown = () => {
  const { settings, selectedChain, setSelectedChain } = useContext(globalContext);
  const { isConnected } = useWeb3ModalAccount();
  const [togglePopup, setTogglePopup] = useState(false);
  const chainlist = useGetChains();

  const chindropdownRef = useRef();

  useEffect(() => {
    const closeModal = (e) => {
      if (!chindropdownRef?.current?.contains(e?.target)) {
        setTogglePopup(false);
      }
    };
    document.body.addEventListener("click", closeModal);
    return () => document.body.addEventListener("click", closeModal);
  }, []);

  const switchChain = async (chain) => {
    try {
      setTogglePopup(false);
      if (isConnected) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chain?.chainId.toString(16)}` }],
        });
      } else {
        setSelectedChain(chain);
      }
    } catch {}
  };

  return (
    <div className="flex items-center" ref={chindropdownRef}>
      <button
        className="p-2 px-6 flex justify-center items-center"
        onClick={() => setTogglePopup(!togglePopup)}
      >
        <img
          src={selectedChain?.icon ? selectedChain?.icon : "assets/images/unknown.svg"}
          className="mx-1 mr-3"
          style={{ height: "20px" }}
        />
        <p className="sm:block hidden">{selectedChain?.name ? selectedChain?.name : "Networks"}</p>
      </button>
      <div
        // ref={ref}
        id="chainDropdownPopupContainer"
        className={`w-[250px] absolute z-30 md:left-0 left-[-10%] p-1 px-2 top-[100%] my-2 rounded-lg backdrop-blur-md bg-black-shade/30 ${
          togglePopup ? "" : "hidden"
        }`}
      >
        <ul>
          {chainlist && chainlist?.mainnets
            ? chainlist?.mainnets?.map((item) => {
                return (
                  <li
                    className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer"
                    key={item.chainId}
                    onClick={() => switchChain(item)}
                  >
                    <div className="flex justify-between">
                      <div className="flex justify-start items-center">
                        <img
                          src={item.icon ? item.icon : "assets/images/unknown.svg"}
                          style={{ height: "20px" }}
                          className="mx-1 mr-3"
                        />
                        <p>{item?.name}</p>
                      </div>
                      <div className="flex justify-end mx-1 items-center">
                        {item.chainId === selectedChain?.chainId && (
                          <div className="green-shadow-dot mr-3"></div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })
            : ""}
          {settings && settings?.testnet && chainlist && chainlist?.mainnets
            ? chainlist?.testnets?.map((item) => {
                return (
                  <li
                    className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer"
                    key={item.chainId}
                    onClick={() => switchChain(item)}
                  >
                    <div className="flex justify-between">
                      <div className="flex justify-start items-center">
                        <img
                          src={item.icon ? item.icon : "assets/images/unknown.svg"}
                          style={{ height: "20px" }}
                          className="mx-1 mr-3"
                        />
                        <p>{item?.name}</p>
                      </div>
                      <div className="flex justify-end mx-1 items-center">
                        {item.chainId === selectedChain?.chainId && (
                          <div className="green-shadow-dot mr-3"></div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
    </div>
  );
};

export default ChainDropdown;
