import { useMemo } from "react";
import { chainInfoTestnet, chainInfoMainnet } from "../constants/chains";
import { walletChains } from "../constants/walletChains";

const useGetChains = () => {
  const chailList = useMemo(() => {
    let mainnets = Array.from(chainInfoMainnet, ([, value]) => value);
    let newmainnets = [];
    for (let i = 0; i < mainnets.length; i++) {
      let find = walletChains.find((item) => item.chainId === mainnets[i].chainId);
      if (find) {
        newmainnets.push({ ...find, ...mainnets[i] });
      } else {
        newmainnets.push({ ...mainnets[i] });
      }
    }
    let testnets = Array.from(chainInfoTestnet, ([name, value]) => value);
    let newtestnet = [];
    for (let i = 0; i < testnets.length; i++) {
      let find = walletChains.find((item) => item.chainId === testnets[i].chainId);
      if (find) {
        newtestnet.push({ ...find, ...testnets[i] });
      } else {
        newtestnet.push({ ...testnets[i] });
      }
    }
    return { mainnets: [...newmainnets], testnets: [...newtestnet] };
  }, [walletChains, chainInfoTestnet, chainInfoMainnet]);
  return chailList;
};

export default useGetChains;
