import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import TokenModal from "./TokenModal";

const SwapInputBox = ({ title, setShow, selecteToken, inputValue, handleInput }) => {
  // const [toggleModal, setToggleModal] = useState(false);
  return (
    <>
      <div className="w-full my-5">
        <div className="flex justify-between">
          <p>{title}</p>
          <p>Balance: {selecteToken?.balance ? selecteToken?.balance : "0"}</p>
        </div>
        <div className="w-full p-2 border rounded-xl my-2 flex justify-between">
          <div className="flex">
            {selecteToken ? (
              <button className="flex px-2 items-center text-lg rounded-lg" onClick={() => setShow(true)}>
                <img
                  src={selecteToken.icon ? selecteToken.icon : "assets/images/unknown.svg"}
                  width="20px"
                  className="mr-1"
                />
                <p>{selecteToken?.symbol}</p>&nbsp;
                <div>
                  <FaAngleDown />
                </div>
              </button>
            ) : (
              <button className="flex px-2 items-center text-lg rounded-lg" onClick={() => setShow(true)}>
                Select&nbsp;
                <div>
                  <FaAngleDown />
                </div>
              </button>
            )}
          </div>
          <div className="flex justify-end">
            <input
              className="outline-none bg-transparent p-0 text-xl text-end w-full mx-4 mr-2"
              style={{ background: "transparent" }}
              placeholder="0.0"
              value={inputValue}
              onChange={(e) => {
                if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) handleInput(e.target.value);
              }}
            />
            <button className="text-md p-2 py-1 bg-black-shade rounded-xl hover:opacity-75">Max</button>
          </div>
        </div>
      </div>
      {/* <TokenModal show={toggleModal} setShow={setToggleModal} /> */}
    </>
  );
};

export default SwapInputBox;
