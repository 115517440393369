export const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

const ethereum = {
  chainId: 1,
  name: "Ethereum Mainnet",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://eth-pokt.nodies.app",
};

const bscMainnet = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed.binance.org/",
};

const bscTestnet = {
  chainId: 97,
  name: "Binance smart chain Testnet",
  currency: "TBNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
};

const sepoliaTestnet = {
  chainId: 11155111,
  name: "Sepolia test network",
  currency: "SepoliaETH",
  explorerUrl: "https://sepolia.etherscan.io/",
  rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
};

export const walletChains = [bscMainnet, ethereum, bscTestnet, sepoliaTestnet];
