export const TestnetChainId = {
  BINANCE_TEST: 97,
  SEPOLIA_TEST: 11155111,
};

export const MainnetChainId = {
  ETHEREUM: 1,
  BINANCE: 56,
};

export const combineChains = { ...TestnetChainId, ...MainnetChainId };

export const networksTestnet = Array.from(Object.values(TestnetChainId));
export const networksMainnet = Array.from(Object.values(MainnetChainId));

// testnet chains
export const chainInfoTestnet = new Map();

chainInfoTestnet.set(TestnetChainId.BINANCE_TEST, {
  router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  chainId: TestnetChainId.BINANCE_TEST,
  icon: "assets/images/chain-icons/bsctestnet.svg",
  isMainnet: false,
  urlname: "bscTestnet",
});
chainInfoTestnet.set(TestnetChainId.SEPOLIA_TEST, {
  router: "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008",
  chainId: TestnetChainId.SEPOLIA_TEST,
  icon: "assets/images/chain-icons/ethereum.svg",
  isMainnet: false,
  urlname: "sepoliaTestnet",
});

// mainnet chians
export const chainInfoMainnet = new Map();

chainInfoMainnet.set(MainnetChainId.ETHEREUM, {
  router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  chainId: MainnetChainId.ETHEREUM,
  icon: "assets/images/chain-icons/ethereum.svg",
  isMainnet: true,
  urlname: "ethereum",
});
chainInfoMainnet.set(MainnetChainId.BINANCE, {
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  chainId: MainnetChainId.BINANCE,
  icon: "assets/images/chain-icons/bscmainnet.svg",
  isMainnet: true,
  urlname: "bsc",
});
