import React, { useState } from "react";
import TokenModal from "../components/TokenModal";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import SettingDropdown from "../components/SettingDropdown";
import { FiPlus } from "react-icons/fi";

const ImportPool = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="min-h-full z-10 w-full flex justify-center text-white">
      <div className="my-8 z-10 md:w-[450px] w-[90%] rounded-xl md:p-6 p-4 backdrop-blur-sm bg-black-shade/30">
        <div className="flex justify-between">
          <div className="flex">
            <div className="rounded-full bg-black flex text-white text-md">
              <button className="p-2 px-3 rounded-full hover:opacity-75" onClick={() => navigate("/pool")}>
                <FaArrowLeftLong />
              </button>
            </div>
            <div className="mx-2 flex items-center">
              <p className="text-lg">Import Pool</p>
            </div>
          </div>
          <div className="flex">
            <div>
              <button className="p-2 mx-1 text-white bg-black-shade rounded-xl hover:opacity-75">
                <IoIosInformationCircleOutline size={25} />
              </button>
            </div>
            <SettingDropdown />
          </div>
        </div>
        <div className="w-full p-2 border rounded-xl my-2 flex justify-between mt-5">
          <button className="flex w-full px-2 items-center text-lg rounded-lg justify-between">
            <div className="flex">
              <img src="assets/images/svgs/coins-swap.svg" width="20px" className="mr-1" />
              <p>USDT</p>&nbsp;
            </div>
            <div>
              <FaAngleDown />
            </div>
          </button>
        </div>
        <div className="flex justify-center">
          <FiPlus size={25} />
        </div>
        <div className="w-full p-2 border rounded-xl my-2 flex justify-between">
          <button className="flex w-full px-2 items-center text-lg rounded-lg justify-between">
            <div className="flex">
              <img src="assets/images/svgs/coins-swap.svg" width="20px" className="mr-1" />
              <p>USDT</p>&nbsp;
            </div>
            <div>
              <FaAngleDown />
            </div>
          </button>
        </div>
        <div className="mt-4">
          <a
            type="button"
            className="py-7 bg-black-shade/20 border rounded-lg flex justify-center items-center"
          >
            Select a token to find your liquidity.
          </a>
        </div>
      </div>
      <TokenModal show={toggleModal} setShow={setToggleModal} />
    </div>
  );
};

export default ImportPool;
