import * as chains from "./chains";

const ETHEREUM = [
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    icons: "https://etherscan.io/token/images/weth_28.png",
    special: true,
  },
  {
    name: "Token USDC",
    symbol: "USDC",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    icon: "https://etherscan.io/token/images/centre-usdc_28.png",
    icons: "",
    special: true,
  },
  {
    name: "Arbitrum",
    symbol: "ARB",
    address: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
    icons: "https://etherscan.io/token/images/arbitrumone2_32.png",
    special: true,
  },
];

const BSC = [
  {
    name: "Wrapped BNB",
    symbol: "WBNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    icons: "https://bscscan.com/token/images/bnbchain2_32.png",
    special: true,
  },
  {
    name: "dForce USDT",
    symbol: "iUSDT",
    address: "0x0BF8C72d618B5d46b055165e21d661400008fa0F",
    icon: "https://bscscan.com/token/images/dforce-iusdt_32.png",
    icons: "",
    special: true,
  },
  {
    name: "USDC",
    symbol: "anyUSDC",
    address: "0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2",
    icons: "https://bscscan.com/token/images/usdcgno_32.png",
    special: true,
  },
];

const SEPOLIA = [
  {
    name: "Sepolia testETH",
    symbol: "SepoliaETH",
    address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
    icons: "",
    special: true,
  },
  {
    name: "Token1 ",
    symbol: "TKN1",
    address: "0x02e757D47aDc8d9D099EC5f7807C1ceeCfdcF443",
    icons: "",
    special: true,
  },
];

const BINANCECHAINTESTNET = [
  {
    name: "BNB Testnet",
    symbol: "TBNB",
    address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    icons: "",
    special: true,
  },
  {
    name: "Token 1",
    symbol: "TKN1",
    address: "0x5b2F5217B0C8097CDa3aCcB9569CA206649F9D52",
    icons: "",
    special: true,
  },
  {
    name: "Token 2",
    symbol: "TKN2",
    address: "0xe151F4B915366F13F02325Ec04BeBe663E0212D6",
    icons: "",
    special: false,
  },
  {
    name: "Bsw",
    symbol: "BSW",
    address: "0x462E75355385fDDb19B4F1A7A6Fca66caC8Cc17a",
    icons: "",
    special: false,
  },
];

const COINS = new Map();
COINS.set(chains.combineChains.ETHEREUM, ETHEREUM);
COINS.set(chains.combineChains.BINANCE, BSC);
COINS.set(chains.combineChains.BINANCE_TEST, BINANCECHAINTESTNET);
COINS.set(chains.combineChains.SEPOLIA_TEST, SEPOLIA);
export default COINS;
