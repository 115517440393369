import { useState } from "react";

const RangeSlider = ({ onChange }) => {
  const [sliderValue, setSliderValue] = useState(0);

  const onSlide = (e) => {
    setSliderValue(e.target.value);
  };

  return (
    <div className="range-slider">
      {/* <p>{slider.label}</p> */}
      <input
        type="range"
        min={0}
        max={100}
        value={sliderValue}
        onChange={(e) => onSlide(e)}
        className="w-full bg-black-shade"
        id="myRange"
      />
    </div>
  );
};
export default RangeSlider;
