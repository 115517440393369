import { ethers } from "ethers";

export function formatAddress(text, size = 5) {
  if (text) {
    return text.substr(0, size) + "..." + text.substr(size - size * 2, size);
  } else {
    return "";
  }
}

export function formatWeiToEtherFixed(value) {
  try {
    const ethValue = Number(ethers.utils.formatEther(value));
    if (ethValue < 0) {
      return 0;
    } else if (ethValue > 0 && ethValue < 1) {
      return ethValue.toFixed(4);
    } else if (ethValue >= 1 && ethValue < 10) {
      return ethValue.toFixed(3);
    } else if (ethValue >= 10 && ethValue < 1000) {
      return ethValue.toFixed(2);
    } else if (ethValue >= 1000 && ethValue < 10000) {
      return ethValue.toFixed(1);
    } else {
      return Math.round(ethValue);
    }
  } catch (err) {
    console.log("error in formatWeiToEtherFixed : ", err);
    return 0;
  }
}

export function formatEtherFixed(value) {
  try {
    const ethValue = Number(value);
    if (ethValue < 0) {
      return 0;
    } else if (ethValue > 0 && ethValue < 1) {
      return ethValue.toFixed(4);
    } else if (ethValue >= 1 && ethValue < 10) {
      return ethValue.toFixed(3);
    } else if (ethValue >= 10 && ethValue < 1000) {
      return ethValue.toFixed(2);
    } else if (ethValue >= 1000 && ethValue < 10000) {
      return ethValue.toFixed(1);
    } else {
      return Math.round(ethValue);
    }
  } catch (err) {
    console.log("error in formatWeiToEtherFixed : ", err);
    return 0;
  }
}
