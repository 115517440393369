import React, { useState, useEffect, useRef, useContext } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import Switch from "./common/Switch";
import { globalContext } from "../GlobalContext";

const SettingDropdown = () => {
  const { settings, setSettings } = useContext(globalContext);
  const [togglePopup, setTogglePopup] = useState(false);

  const [slippageError, setSlippageError] = useState({ error: "", type: "info" });

  const settingMenuRef = useRef();

  useEffect(() => {
    const closeModal = (e) => {
      if (!settingMenuRef?.current?.contains(e?.target)) {
        setTogglePopup(false);
      }
    };
    document.body.addEventListener("click", closeModal);
    return () => document.body.addEventListener("click", closeModal);
  }, []);

  const handleSettings = (value, action) => {
    if (action === "updateslipagebybtn") {
      setSettings((prev) => ({ ...prev, slippagePlaceholder: value }));
      localStorage.setItem(
        "dex:settings:[15xs3]",
        JSON.stringify({ ...settings, slippagePlaceholder: value })
      );
    } else if (action === "updateslipage") {
      setSettings((prev) => ({ ...prev, slippage: value }));
      localStorage.setItem("dex:settings:[15xs3]", JSON.stringify({ ...settings, slippage: value }));
    } else if (action === "updateslipageblur") {
      setSettings((prev) => ({
        ...prev,
        slippagePlaceholder:
          settings?.slippage && Number(settings?.slippage) < 50 ? settings?.slippage : "0.5",
        slippage: "",
      }));
      localStorage.setItem(
        "dex:settings:[15xs3]",
        JSON.stringify({
          ...settings,
          slippagePlaceholder:
            settings?.slippage && Number(settings?.slippage) < 50 ? settings?.slippage : "0.5",
          slippage: "",
        })
      );
    } else if (action === "updatedeadline") {
      setSettings((prev) => ({ ...prev, deadline: value }));
      localStorage.setItem("dex:settings:[15xs3]", JSON.stringify({ ...settings, deadline: value }));
    } else if (action === "updatedeadlineblur") {
      setSettings((prev) => ({
        ...prev,
        deadlinePlaceholder: settings?.deadline && Number(settings?.deadline) > 0 ? settings?.deadline : "20",
        deadline: "",
      }));
      localStorage.setItem(
        "dex:settings:[15xs3]",
        JSON.stringify({
          ...settings,
          deadlinePlaceholder:
            settings?.deadline && Number(settings?.deadline) > 0 ? settings?.deadline : "20",
          deadline: "",
        })
      );
    } else if (action === "updateexpertmode") {
      setSettings((prev) => ({ ...prev, expertMode: !settings?.expertMode }));
      localStorage.setItem(
        "dex:settings:[15xs3]",
        JSON.stringify({ ...settings, expertMode: !settings?.expertMode })
      );
    } else if (action === "updateshowtestnet") {
      setSettings((prev) => ({ ...prev, testnet: !settings?.testnet }));
      localStorage.setItem(
        "dex:settings:[15xs3]",
        JSON.stringify({ ...settings, testnet: !settings?.testnet })
      );
    }
  };

  useEffect(() => {
    try {
      if (localStorage.getItem("dex:settings:[15xs3]")) {
        let setting = JSON.parse(localStorage.getItem("dex:settings:[15xs3]"));
        setSettings({ ...setting });
      }
    } catch {}
  }, []);

  useEffect(() => {
    if (settings) {
      if (Number(settings?.slippagePlaceholder) <= 0) {
        setSlippageError({ error: "Your transaction may fail", type: "info" });
      } else if (Number(settings?.slippage) >= 50) {
        setSlippageError({ error: "Enter a valie slippage percentage", type: "error" });
      } else if (Number(settings?.slippagePlaceholder) > 5) {
        setSlippageError({ error: "Your transaction may be frontrun", type: "info" });
      } else {
        setSlippageError({ error: "", type: "info" });
      }
    }
  }, [settings]);

  return (
    <div className="relative" ref={settingMenuRef}>
      <button
        className="p-2 mx-1 text-white bg-black-shade rounded-xl hover:opacity-75"
        onClick={() => setTogglePopup(!togglePopup)}
      >
        <IoSettingsOutline size={25} />
      </button>
      <div
        // ref={ref}
        id="settingPopupContainer"
        className={`w-[310px] absolute z-30 right-0 p-1 px-2 top-[100%] my-2 rounded-lg backdrop-blur-xl bg-black-shade/90 ${
          togglePopup ? "" : "hidden"
        }`}
      >
        <ul>
          <li className="my-1 p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
            <small>Slippage Tolerance</small>
            <div className="flex my-2 gap-2">
              <button
                className={`border outline-none rounded-[40px] p-1 px-3 hover:bg-black ${
                  settings.slippagePlaceholder === "0.1" ? "bg-black" : ""
                }`}
                onClick={() => handleSettings("0.1", "updateslipagebybtn")}
              >
                0.1%
              </button>
              <button
                className={`border outline-none rounded-[40px] p-1 px-3 hover:bg-black ${
                  settings.slippagePlaceholder === "0.5" ? "bg-black" : ""
                }`}
                onClick={() => handleSettings("0.5", "updateslipagebybtn")}
              >
                0.5%
              </button>
              <button
                className={`border outline-none rounded-[40px] p-1 px-3 hover:bg-black ${
                  settings.slippagePlaceholder === "1.0" ? "bg-black" : ""
                }`}
                onClick={() => handleSettings("1.0", "updateslipagebybtn")}
              >
                1.0%
              </button>
              <div className="border rounded-[40px] p-1 px-3 hover:bg-black flex min-w-[10px] items-center">
                <input
                  type="text"
                  className="min-w-[10px] outline-none"
                  style={{ backgroundColor: "transparent" }}
                  value={settings?.slippage}
                  placeholder={settings?.slippagePlaceholder}
                  onChange={(e) => handleSettings(e.target.value, "updateslipage")}
                  onBlur={() => handleSettings(null, "updateslipageblur")}
                />
                %
              </div>
            </div>
            {slippageError?.error ? (
              <small className={`${slippageError?.type === "error" ? "text-red" : "text-yellow"}`}>
                {slippageError?.error}
              </small>
            ) : (
              ""
            )}
          </li>
          <hr className="opacity-25 my-1" />
          <li className="p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
            <div className="flex items-center justify-between">
              <p>Tx deadline (mins)</p>
              <p>
                <input
                  type="text"
                  className="border text-center rounded-[40px] p-1 px-3 hover:bg-black flex w-[60px] outline-none"
                  style={{ backgroundColor: "transparent" }}
                  value={settings?.deadline}
                  placeholder={settings?.deadlinePlaceholder}
                  onChange={(e) => handleSettings(e.target.value, "updatedeadline")}
                  onBlur={() => handleSettings(null, "updatedeadlineblur")}
                />
              </p>
            </div>
          </li>
          <li className="p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
            <div className="flex items-center justify-between">
              <p>Expert Mode</p>
              <p>
                <Switch
                  toggleSwitch={settings?.expertMode}
                  setToggleSwitch={() => handleSettings(null, "updateexpertmode")}
                />
              </p>
            </div>
          </li>
          <li className="p-2 rounded-xl hover:bg-black-shade/60 cursor-pointer">
            <div className="flex items-center justify-between">
              <p>Show testnet</p>
              <p>
                <Switch
                  toggleSwitch={settings?.testnet}
                  setToggleSwitch={() => handleSettings(null, "updateshowtestnet")}
                />
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingDropdown;
