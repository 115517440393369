import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const LiquidityAccordion = () => {
  const navigate = useNavigate();
  const [accordionToggle, setAccordionToggle] = useState(false);
  return (
    <div className="mb-1">
      <div
        className={`p-2 rounded-lg cursor-pointer flex justify-between items-center hover:bg-black-shade/25 ${
          accordionToggle ? "bg-black-shade/25" : ""
        }`}
        onClick={() => setAccordionToggle(!accordionToggle)}
      >
        <div className="flex">
          <img src="assets/images/svgs/coins-swap.svg" width="20px" className="mx-1" />
          <img src="assets/images/svgs/coins-swap.svg" width="20px" />
          <p className="mx-2">BUSD/USDT</p>
        </div>
        <p className={`origin-center ease-in-out duration-300 ${accordionToggle ? "" : "rotate-90"}`}>
          <FaAngleDown />
        </p>
      </div>
      <div
        className={`px-4 overflow-hidden duration-300 ${
          accordionToggle ? "max-h-[600px] ease-in" : "max-h-0 ease-out"
        }`}
      >
        <div className="flex justify-between">
          <p className="text-white/65">Pooled BUSD</p>
          <p className="flex">
            11324
            <img src="assets/images/svgs/coins-swap.svg" width="20px" className="mx-1" />
          </p>
        </div>
        <div className="flex my-1 justify-between">
          <p className="text-white/65">Pooled USDT</p>
          <p className="flex">
            11324
            <img src="assets/images/svgs/coins-swap.svg" width="20px" className="mx-1" />
          </p>
        </div>
        <div className="flex my-1 justify-between">
          <p className="text-white/65">Your pool tokens</p>
          <p className="text-blue">2177</p>
        </div>
        <div className="flex my-1 justify-between">
          <p className="text-white/65">Pooled BUSD</p>
          <p className="text-blue">99.49%</p>
        </div>
        <div className="flex my-1 gap-2">
          <button className="w-1/2 bg-blue text-lg p-2 rounded-lg hover:opacity-75">Add</button>
          <button
            className="w-1/2 bg-red text-lg p-2 rounded-lg hover:opacity-75"
            onClick={() => navigate("/remove-liquidity")}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiquidityAccordion;
