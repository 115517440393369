import React from "react";

const Switch = ({ toggleSwitch, setToggleSwitch }) => {
  return (
    <button
      className={`w-[45px] p-1 relative rounded-full flex border ease-in-out duration-300 ${
        toggleSwitch ? "justify-end" : "justify-start"
      }`}
      onClick={setToggleSwitch}
    >
      <span
        className={`rounded-full w-[15px] h-[15px]  ease-in-out duration-300 ${
          toggleSwitch ? "bg-blue" : "bg-black"
        }`}
      ></span>
    </button>
  );
};

export default Switch;
